// import "../../i18n";
import './layout.scss';

import styled from 'styled-components';
import React, { FC } from 'react';
import { Col, Container, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const LogoLink = styled(Link)`
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
`;

const Logo: FC = () => (
  <LogoLink to="/">
    <img src="/images/logo.png" alt="Logo" height={60} />
  </LogoLink>
);

const Wrapper = styled.div`
  border-bottom: 2px solid #f1f1f1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
`;

const Title = styled.h1`
  font-size: 2.5em;
  flex: 1;
  text-align: center;
  font-weight: 600;

  a:hover {
    text-decoration: none;
  }
`;

const Body = styled.div`
  img {
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
`;

const Blog: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <Logo />
            <Title>
              <Link to="/blog">Collaboration</Link>
            </Title>
          </Header>
        </Container>
      </Wrapper>
      <Container>
        <Body>{children}</Body>
      </Container>
    </>
  );
};

export default Blog;

import styled from 'styled-components';
import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Blog from '../components/blog';
import SEO from '../components/seo';

interface TransItem {
  locale: string;
  content: string;
}

interface PostData {
  post: {
    slug: string;
    title: string;
    imageUrl: string;
    content: string;
    translations: TransItem[];
  };
}

interface Props {
  readonly data: PostData;
  readonly pageContext: {
    slug: string;
  };
}

const Poster = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const BlogPost: FC<Props> = ({ data }) => {
  const { i18n } = useTranslation();
  const { post } = data;
  const translation = post.translations.find(item => item.locale === i18n.language);
  const content = translation?.content || post.content;

  return (
    <Blog>
      <SEO title={post.title} article />
      <Poster>
        <img src={post.imageUrl} alt={post.title} />
      </Poster>
      <h1>{post.title}</h1>
      <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: content }} />
    </Blog>
  );
};

export const query = graphql`
  query GetPost($slug: String!) {
    post(slug: { eq: $slug }) {
      slug
      title
      imageUrl
      content
      translations {
        locale
        content
      }
    }
  }
`;

export default BlogPost;
